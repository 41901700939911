import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';

import InstagramFeed from 'components/instagram-feed';
import Hero from 'components/hero';
import DefaultLayout from 'layouts/default';

const title = 'Dom na granicy';

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMdx;
  const { edges: instaPosts } = data.allInstaNode;

  return (
    <DefaultLayout
      lang="en"
      metaDescription="Welcome to our blog about renovating a brick cottage on the German-Polish border, setting up a permaculture garden and all the things that tie into this."
      title={title}
      url="/"
    >
      <Hero image={data.house.childImageSharp.fluid} title={title} />
      <main>
        <ul className="articles-list">
          {posts
            .filter(({ node: post }) =>
              post.frontmatter.path.startsWith('/en/')
            )
            .map(({ node: post }) => (
              <li className="articles-list-item" key={post.frontmatter.title}>
                <article>
                  <Link
                    className="articles-list-link"
                    to={post.frontmatter.path}
                  >
                    <Img
                      className="u-image articles-list-image"
                      fluid={
                        post.frontmatter.featuredImage
                          ? post.frontmatter.featuredImage.childImageSharp.fluid
                          : data.placeholder.childImageSharp.fluid
                      }
                    />
                    <div className="articles-list-text">
                      <h2 className="articles-list-title">
                        {post.frontmatter.title}
                      </h2>
                      <time className="articles-list-date">
                        {post.frontmatter.date}
                      </time>
                      <p>{post.excerpt}</p>
                      <p className="articles-list-read-more">
                        Read more{' '}
                        <span className="articles-list-read-more-arrow">›</span>
                      </p>
                    </div>
                  </Link>
                </article>
              </li>
            ))}
        </ul>
        <InstagramFeed posts={instaPosts} title="Instagram feed" />
      </main>
    </DefaultLayout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexEnQuery {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 450)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    house: file(relativePath: { eq: "house.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 3) {
      edges {
        node {
          id
          likes
          comments
          mediaType
          preview
          original
          timestamp
          caption
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          # Only available with the public api scraper
          thumbnails {
            src
            config_width
            config_height
          }
          dimensions {
            height
            width
          }
        }
      }
    }
  }
`;
